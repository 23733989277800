@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

$headersFont: "Montserrat";
$textFont: "Montserrat";

$primary-color: #e87b22;
$header-bg-color: black;
$blue-color: #0d77ce;
$light-blue-color: #0d77ce;
$green-color: #09b437;
$light-green-color: #09b437;
$orange-color: #f5af17;
$light-orange-color: #f5af17;
$red-color: #cf3d3d;
$light-red-color: #cf3d3d;
$grey-color: #666;
$placeholder-color: #747474;
$wrapper-bg-color: #eceff1;

$navbarLogoWidthSm: 110px;
$navbarLogoWidthMd: 135px;
$navbarLogoWidthLg: 180px;

$miniNavbarLogoWidthSm: 135px;
$miniNavbarLogoWidthMd: 160px;
$miniNavbarLogoWidthLg: 160px;

$navbarDrawerBreakpoint: 900px;

// wrapper min height
// frontpage
$wrapperFWMinheightXs: 80px;
$wrapperFWMinheightSm: 80px;
$wrapperFWMinheightMd: 170px;
$wrapperFWMinheightLg: 170px;
$wrapperFWMinheightXl: 240px;
// static page
$wrapperMinheightXs: 100px;
$wrapperMinheightSm: 100px;
$wrapperMinheightMd: 153px;
$wrapperMinheightLg: 153px;
$wrapperMinheightXl: 222px;

$slideBg: black;
