@import "./theme-settings.scss";

.add-new-styles-here {
  color: blue;
}
.mini-header {
  flex-wrap: wrap;
  a {
    margin-bottom: 30px;
    flex: 0 0 100%;
  }
  @media screen and (min-width: 576px) {
    flex-wrap: nowrap;
    a {
      margin-bottom: 0;
      flex: 0 0 auto;
    }
  }
  @media screen and (min-width: 576px) {
    .mini-header-text {
      h1,
      h2 {
        font-size: 25px;
        line-height: 27px;
      }
    }
  }
}

.ant-input-group-compact {
  .ant-input {
    font-size: 13px;
  }
}

.main-navbar .logo span {
  display: none;
  @media screen and (min-width: 576px) {
    display: block;
  }
}
.navbar-menu > ul {
  margin-top: 80px;
  @media screen and (min-width: 900px) {
    margin-top: 0;
  }
}
.auth-layout:not(.full-width) .content-wrapper {
  margin-top: 100px;
  @media screen and (min-width: 900px) {
    margin-top: 0;
  }
}
.languageSelector .languageButton {
  font-size: 14px !important;
}
.auth-layout .content-wrapper-full {
  margin-top: 98px;
  @media screen and (min-width: 900px) {
    margin-top: 0;
  }
}

.noauth-layout {
  align-items: flex-start;
  background: none;
  @media screen and (min-width: 1024px) {
    padding: 40px 50px;
  }
  > main {
    left: 10%;
  }
  .fullsize-bg {
    z-index: -1;
  }
}

@media screen and (min-width: 1400px){
.slider-2 .slide .slide-content .slide-desc .slide-title {
  font-size: 35px;
 }
 .slider-2 .slide .slide-content .slide-desc .slide-text {
   font-size: 18px
 }
}
@media screen and (min-width: 1024px){
.slide {
  min-height: 420px;
}
.slider-2 .slide .slide-bg {
  height: auto;
}}